<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Edit Address</h3>
      </div>
      <AddressForm :address-type="addressType" ref="addressForm" :address-id="this.$route.params.id"></AddressForm>
    </form>

  </div>
</template>

<script>
import AddressForm from "./AddressForm";
export default {
  name: "EditAddress",
  components: { AddressForm },
  data() {
    return {
      addressSaved: false,
      loading: false,
      addressType: 'billing',
      errors: null
    };
  },
}
</script>

<style scoped>

</style>